<template>
    <div class="LoadsContent">

        

        <DropDownDatePicker v-model="filter.date" placeholder="Select date" range mode="date" style="margin-bottom: 20px;"/>

        <div class="stats" v-if="loadsData && loadsData.stats">

            <div class="statsGroup">
                <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                <div class="_value">${{ priceConverter(loadsData.stats.gross) }}</div>
            </div>

            <div class="statsGroup">
                <div class="_title"><i class="fa-solid fa-box"></i><span>Number of loads</span></div>
                <div class="_value">{{ loadsData.stats.numOfLoads }}</div>
            </div>

        </div>


        <div class="loadsList" id="listOfLoads">
            <div class="title">
                <h2>Loads</h2>
                <div class="filterOptions">
                    
                    <select v-model="filter.driver" class="dropdown-classic">
                        <option :value="null">All drivers</option>
                        <option :key="driver._id" :value="driver._id" v-for="driver of drivers">{{ driver.name }}, Truck {{ driver.truck[0] ? driver.truck[0].name : 'No Truck Assigned' }}</option>
                        <option value="NOT_ASSIGNED">Not Assigned</option>
                    </select>

                </div>
            </div>
            
            <div class="items" v-if="loadsData && loadsData.list && loadsData.list.length > 0">
                <div class="item header">
                    <div>Load #</div>
                    <div>Date</div>
                    <div>Origin</div>
                    <div>Destination</div>
                    <div>Broker</div>
                    <div>Weight</div>
                    <div>Length</div>
                    <div>Driver</div>
                    <div>Rate</div>
                    <div>Status</div>
                </div>
                <LoadItem class="item" :key="load._id" v-for="load of loadsData.list" :load="load" @status="(status) => { updateStatus(load._id, status) }"/>
            </div>
            <div class="noLoadsMessage" v-else>
                <div class="icon"><i class="fa-solid fa-parachute-box"></i></div>
                <div class="message">You have no loads for this week.</div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import WeekPicker from '../components/WeekPicker.vue'
import LoadItem from '../components/LoadItem.vue'

    export default {
        components: {
            WeekPicker,
            LoadItem
        },
        data() {
            return {
                loadsData: {},
                showCalendar: false,
                filter: {
                    yearWeek: null,
                    driver: null,
                    date: null
                },
                drivers: []
            }
        },
        computed: {
            weekDateRange() {
                let date = moment.utc(this.filter.yearWeek, "YYYYWW");
                let endOfWeek = date.clone().endOf('isoWeek');
                return date.format("ddd Do MMM, YY") + " - " + endOfWeek.format("ddd Do MMM, YY");
            }
        },
        methods: {
            updateStatus(loadId, status) {
                this.ajax('ChangeLoadStatus', {
                    url: `/changeLoadStatus/${loadId}/${status}`,
                    method: 'PUT'
                }, (err, body) => { });
            },
            getLoadsData() {

                let fullQuery = JSON.parse(JSON.stringify(this.filter));
                if(fullQuery.date) {
                    fullQuery.start_date = fullQuery.date.start;
                    fullQuery.end_date = fullQuery.date.end;
                    delete fullQuery.date;
                }
                let query = new URLSearchParams(fullQuery).toString();


                this.ajax('getLoadsData', {
                    method: 'GET',
                    url: `/myLoads?${query}`
                }, (err, body) => {
                    this.loadsData = body;
                });
            },
            getDrivers() {
                this.ajax('getListOfDrivers', {
                    url: '/myDrivers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            },
        },
        mounted() {

            let date = moment.utc().startOf('isoWeek');
            this.filter.date = {
                start: date.clone().toDate(),
                end: date.clone().endOf('isoWeek').toDate(),
            }

            this.getLoadsData();
            this.getDrivers();
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    this.getLoadsData();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.weekSelectorHolder {
    display: flex;
    align-items: center;
}

.LoadsContent {
    display: grid;
    row-gap: 20px;
    padding: 30px 0;
}


.items {
    overflow-x: auto;
    .item {
        background: $bgSecondary;
        padding: 0 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        display: grid;
        column-gap: 20px;
        grid-template-columns: 75px 50px minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) 100px 75px 120px 75px 125px;
        font-size: 13px;
        min-width: 1160px;
        height: 64px;
        &:hover {
            z-index: 2;
            box-shadow: $boxShadow;
        }
        &:last-child {
            border: 0;
        }
        &.header {
            background: $bgSecondary;
            box-shadow: $boxShadow;
            height: 44px;
            text-transform: uppercase;
            font-weight: bold;
            border-bottom: 1px solid $borderColor;
            font-size: 12px;
        }
    }
}

.weekSelector {
    position: relative;
    z-index: 2;
    .selectedWeekRange {
        border: 0;
        background: transparent;
        border-radius: 5px;
        height: 40px;
        padding: 0 20px;
        font-size: 14px;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;
        color: $text;
        transition: ease 0.3s;
        .icon {
            font-size: 14px;
            transition: ease 0.3s;
        }
        &:hover {
            background: $buttonColor2;
            color: $buttonColor;
        }
    }
    .dropDown {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(20px);
        width: 400px;
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        overflow: hidden;
    }
}

.loadsList {
    display: grid;
    row-gap: 20px;
    .list {
        display: grid;
        column-gap: 10px;
        row-gap: 10px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.stats {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;
}

.noLoadsMessage {
    padding: 100px 30px;
    display: grid;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    background: $bgSecondary;
    border-radius: $borderRadius;
    .icon {
        font-size: 50px;
    }
    .message {
        font-size: 14px;
        opacity: 0.8;
    }
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .stats {
        grid-template-columns: minmax(0, 1fr);
    }
}


@media only screen and (max-width: 800px) {
    .weekSelectorHolder {
        justify-content: center;
    }
    .weekSelector {
        .selectedWeekRange {
            font-size: 14px;
        }
        .dropDown {
            left: 50%;
            transform: translate(-50%, 20px);
            max-width: calc(100vw - 40px);
        }
    }
}

@media only screen and (max-width: 600px) {
    .weekSelector {
        .selectedWeekRange {
            font-size: 12px;
        }
    }
}

</style>